import {
  Avatar,
  CardContent,
  Grid,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { axiosClient } from "Utilities/axiosConfig";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";
import ReusableSearch from "components/reusableSearch";
import GridSpinner from "components/LoaderComponent/Grid";
import { makeStyles } from "@material-ui/styles";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import AuthContext from "Utilities/AuthContext";
import { CurrencyContext } from "Utilities/currencyContext";
import ErrorComponent from "components/errorComponent";
import shopContext from "Utilities/shop-context";
import { AddCircleRounded, RemoveCircleRounded } from "@material-ui/icons";
import CardFooter from "components/Card/CardFooter";
import OvalSpinner from "components/LoaderComponent/oval";
import { useHistory } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
  row: {
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
};
const useStyles = makeStyles(styles);

const RestockPage = () => {
  const classes = useStyles();

  const [products, setProducts] = useState();
  //const context = useContext(ShopContext);
  const { returnValue, returnSpecific } = useContext(CurrencyContext);
  const { user } = useContext(AuthContext);
  const [restockProducts, setRestockProducts] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  const [nothing, setNothing] = useState(false);
  const [error, setError] = useState();

  const [supplierLoading, setSupplierLoading] = useState(false);
  const [suppliers, setSuppliers] = useState(null);
  const [supplier, setSupplier] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [complete, setComplete] = useState(false);
  const context = useContext(shopContext);
  let history = useHistory();

  const handleDisplayData = (finds) => {
    if (finds == "Nothing") {
      setNothing(true);
    } else if (finds !== null) {
      setDisplayData(finds);
      setNothing(false);
    } else {
      setDisplayData(products);
      setNothing(false);
    }
  };

  const AddRestockProduct = (product) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          context.addProductToCart({
            id: product.id,
            title: product.name_ar,
            price: product.price,
          });
        }}
      >
        اضافة
      </Button>
    );
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/v1/products", headers);
      let arr = [];
      res.data.map((p) => {
        arr.push([p.id, p.name_ar, p.stock, AddRestockProduct(p)]);
      });
      setProducts(arr);
      setDisplayData(arr);
      setError(false);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  const calculateTotal = () => {
    let total = 0;
    restockProducts.forEach((rp) => {
      total += rp.purchase_price * rp.quantity;
    });
    return total;
  };

  const getSuppliers = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      setSupplierLoading(true);
      const res = await axiosClient.get("/suppliers", headers);
      console.log(res);
      setSuppliers(res);
      setSupplierLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckout = () => {
    let arr = [];
    context.cart.map((p) => {
      let item = {
        product_id: p.id,
        product_name: p.title,
        product_price: p.price,
        purchase_price: 0,
        quantity: p.quantity,
      };
      arr.push(item);
    });
    console.log(arr);
    setRestockProducts(arr);
    setCheckout(true);
    context.clearCart();
  };
  //restock cart functions
  const incrementQuantity = (id) => {
    restockProducts.map((rp) => {
      if (rp.product_id === id) {
        return { ...rp, quantity: rp.quantity++ };
      }
      console.log(restockProducts);
      return rp;
    });
  };

  const decrementQuantity = (id) => {
    restockProducts.map((rp) => {
      if (rp.product_id === id) {
        return { ...rp, quantity: rp.quantity-- };
      }
      console.log(restockProducts);
      return rp;
    });
  };

  const setPurchasePrice = (id, pprice) => {
    restockProducts.map((rp) => {
      if (rp.product_id === id) {
        return {
          ...rp,
          purchase_price: (rp.purchase_price = parseFloat(pprice)),
        };
      }
      console.log(restockProducts);
      return rp;
    });
  };

  const productQuantity = (id, q) => {
    console.log(q);
    restockProducts.map((rp) => {
      if (rp.product_id === id) {
        return {
          ...rp,
          quantity: (rp.quantity = parseInt(q)),
        };
      }
    });
  };

  const submitRestock = async () => {
    let payload = {
      supplier_id: supplier[0],
      supplier_name: supplier[1],
      total: calculateTotal(),
      items: restockProducts,
    };
    console.log(payload);
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/restock", payload, headers);
      console.log(res);
      alert("success!");
      setCheckout(false);
      setComplete(false);
      setRestockProducts([]);
      getData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    getSuppliers();
  }, []);

  if (error) return <ErrorComponent refresh={getData} />;

  if (!products) return <GridSpinner />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="normal"
          color="warning"
          onClick={() => {
            history.push("/rtl/suppliers");
          }}
        >
          الرجوع الى الموردين
        </Button>
      </Grid>
      {context.cart.length > 0 ? (
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={"rose"}>
              <h3 className={classes.cardTitleWhite}>شراء منتجات جديدة</h3>
              <span style={{ color: "black", fontSize: 16 }}>
                اختيار المنتجات و الكمية
              </span>
            </CardHeader>
            <CardBody>
              {context.cart.length > 0
                ? context.cart.map((p) => {
                    return (
                      <Grid item xs={12} sm={12} md={12}>
                        <Card className={classes.root}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                              <div className={classes.details}>
                                <CardContent className={classes.content}>
                                  <Typography component="h5" variant="h5">
                                    {p.title}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  >
                                    {returnValue(p.price)}
                                  </Typography>
                                </CardContent>
                              </div>
                            </Grid>
                          </Grid>
                          <CardFooter className={classes.footer}>
                            {/* <IconButton
                              aria-label="add"
                              onClick={() => {
                                context.addProductToCart(p);
                              }}
                            >
                              <AddCircleRounded />
                            </IconButton>
                            <span style={{ fontSize: 20, fontWeight: "bold" }}>
                              {p.quantity}
                            </span>
                            <IconButton
                              aria-label="remove"
                              onClick={() => {
                                context.removeProductFromCart(p.id);
                              }}
                            >
                              <RemoveCircleRounded />
                            </IconButton> */}
                            <span
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginRight: "auto",
                              }}
                            >
                              {returnValue(p.price * p.quantity)}
                            </span>
                          </CardFooter>
                        </Card>
                      </Grid>
                    );
                  })
                : null}
            </CardBody>
            <CardFooter>
              {checkout ? (
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    // marginRight: "auto",
                  }}
                >
                  {returnValue(calculateTotal())}
                </span>
              ) : null}
              {!checkout ? (
                <Button
                  color="rose"
                  onClick={() => {
                    handleCheckout();
                  }}
                >
                  متابعة
                </Button>
              ) : null}
            </CardFooter>
          </Card>
        </Grid>
      ) : null}
      {checkout ? (
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={complete ? "success" : "info"}>
              <h3 className={classes.cardTitleWhite}> شراء منتجات جديدة</h3>
            </CardHeader>
            <CardBody>
              {restockProducts
                ? restockProducts.map((rp) => {
                    return (
                      <Grid item xs={12} sm={12} md={12}>
                        <Card>
                          <CardBody>
                            <Typography component="h5" variant="h5">
                              {rp.product_name}
                            </Typography>
                            <Typography variant="subtitle" color="subtitle">
                              {`${returnValue(rp.product_price)}`}
                            </Typography>
                            {!complete ? (
                              <Grid container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                  <Typography component="h6" variant="h6">
                                    {"سعر الشراء"}
                                  </Typography>
                                  <TextField
                                    placeholder="سعر الشراء"
                                    type={"text"}
                                    onChange={(e) => {
                                      setPurchasePrice(
                                        rp.product_id,
                                        e.target.value
                                      );
                                    }}
                                    variant="filled"
                                    fullWidth={false}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography component="h6" variant="h6">
                                    {"الكمية"}
                                  </Typography>
                                  <TextField
                                    placeholder="الكمية"
                                    type={"number"}
                                    onChange={(e) => {
                                      productQuantity(
                                        rp.product_id,
                                        e.target.value
                                      );
                                    }}
                                    variant="filled"
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                          </CardBody>
                          <CardFooter className={classes.footer}>
                            {complete ? (
                              <>
                                <span
                                  style={{ fontSize: 20, fontWeight: "bold" }}
                                >
                                  {rp.quantity}
                                </span>
                                <span
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    marginRight: "auto",
                                  }}
                                >
                                  {returnValue(rp.purchase_price * rp.quantity)}
                                </span>
                              </>
                            ) : null}
                          </CardFooter>
                        </Card>
                      </Grid>
                    );
                  })
                : null}
              {!complete ? (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    classes={classes.FormControl}
                    fullWidth
                  >
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      style={{ textAlign: "left" }}
                    >
                      المورد
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={supplier}
                      onChange={(e) => {
                        setSupplier(e.target.value);
                      }}
                      fullWidth={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {suppliers.map((s) => {
                        return (
                          <MenuItem value={[s.id, s.name]}>{s.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
            </CardBody>
            <CardFooter className={classes.footer}>
              {!complete ? (
                <Button
                  color="info"
                  onClick={() => {
                    setComplete(true);
                  }}
                >
                  تثبيت
                </Button>
              ) : complete ? (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      submitRestock();
                    }}
                  >
                    Submit Restock
                  </Button>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      // marginRight: "auto",
                    }}
                  >
                    {returnValue(calculateTotal())}
                  </span>
                </>
              ) : null}
            </CardFooter>
          </Card>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h3 className={classes.cardTitleWhite}>المنتجات</h3>
          </CardHeader>
          <CardBody>
            <ReusableSearch
              apiPath={"/products/search/"}
              handleDisplayData={handleDisplayData}
            />
            {nothing ? (
              <Grid item xs={12} sm={12} md={12} alignItems="center">
                <h3>لا يوجد نتائج</h3>
              </Grid>
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={["ID", "المنتج", "المخزن", "-"]}
                tableData={products}
              />
            )}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RestockPage;
