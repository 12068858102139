import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ user: user, component: Component, ...rest }) => {
  useEffect(() => {
    // console.log("protected route ran", user, Component);
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== null) {
          return <Component />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
