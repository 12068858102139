/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import Button from "components/CustomButtons/Button";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { CurrencyContext } from "Utilities/currencyContext";
import PaymentModal from "components/PaymentModal";
import AuthContext from "Utilities/AuthContext";
import GridSpinner from "components/LoaderComponent/Grid";

const useStyles = makeStyles(styles);

export default function UserDuesPage() {
  const classes = useStyles();
  let history = useHistory();
  const { returnValue } = useContext(CurrencyContext);

  const [userDues, setUserDues] = useState(null);

  const modalToggler = (id, balance) => {
    return <PaymentModal userId={id} balance={balance} refresh={getData} />;
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/userDues", headers);
      let arr = [];

      res.user_dues.map((d) => {
        arr.push([
          d.id,
          d.name_ar,
          returnValue(d.balance),
          modalToggler(d.id, d.balance),
        ]);
      });
      setUserDues(arr);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  if (userDues == null) return <GridSpinner />;
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h3 className={classes.cardTitleWhite}>المستحقات</h3>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Amount", "Date", "-"]}
                tableData={userDues}
                dense
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
