/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { TextField, FormControl, Input } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { axiosClient } from "Utilities/axiosConfig";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { languages } from "../variables/languages";
import Checkbox from "@material-ui/core/Checkbox";
import GoodCard from "components/GoodCard";
import ProductViewPage from "Screens/AddProductPage";
import GridSpinner from "components/LoaderComponent/Grid";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {},
  gridolian: {
    flexGrow: 1,
  },
};

const useStyles = makeStyles(styles);

export default function AddProductPage({
  handleEditMode,
  refresh,
  editProduct,
}) {
  const classes = useStyles();

  const [name, setName] = useState("");

  const [count, setCount] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");

  //const [fileObj, setfileObj] = useState([])
  const [fileArray, setFileArray] = useState([]);
  //const [variants, setVariants] = useState(null);
  const [checked, setChecked] = useState(false);

  const fileSelectedHandler = (e) => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  // const uploadMultipleFiles = (e) => {
  //   setImage(null);
  //   let arr = [];
  //   let filez = [];
  //   let variant = [];
  //   arr.push(e.target.files);
  //   for (let i = 0; i < arr[0].length; i++) {
  //     filez.push(URL.createObjectURL(arr[0][i]));
  //     let vrnt = { index: i, title: "", image: arr[0][i], product: 0 };
  //     variant.push(vrnt);
  //   }
  //   setVariants(variant);
  //   setFileArray(filez);
  //   console.log(variant);
  // };

  const [cats, setCats] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCats = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get("v1/categories", headers);

      setCats(res.data);
      setError(false);
    } catch (err) {
      setError(true);
    }
  };

  const handleEdit = () => {
    if (editProduct) {
      setName(editProduct.name_ar);
      setDescription(editProduct.description);
      setPrice(editProduct.price);
      setCategory(editProduct.category);
    }
  };

  const addProduct = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let payload = {
        name_ar: name,
        description: description,
        price: price,
        stock: 0,
        image: image,
        category: category,
      };

      console.log(payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const res = await axiosClient.post("/products", payload, {
          headers: headers,
        });
        setLoading(false);
        alert("Success!");
        refresh();
      }
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  const submitEditProduct = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let payload = {
        name_ar: name,
        description: description,
        price: price,
        image: image,
        category: category,
      };

      console.log("payload: ", payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const res = await axiosClient.post(
          "/products/" + editProduct.id + "/edit",
          payload,
          {
            headers: headers,
          }
        );
        console.log(res);
        setLoading(false);
        alert("Success!");
        handleEditMode();
        refresh(editProduct.id);
      }
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  // const handleVariants = (title, VID) => {
  //   setVariants((v) =>
  //     v.map((obj) => {
  //       if (obj.index === VID) {
  //         return { ...obj, title: title };
  //       }
  //       return obj;
  //     })
  //   );
  //   console.log(variants);
  // };

  useEffect(() => {
    if (editProduct) {
      handleEdit();
    }
    getCats();
  }, []);

  if (loading) return <GridSpinner />;
  if (!cats) return <GridSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>
                {editProduct ? "اضافة منتج" : "تعديل منتج"}
              </h3>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.name.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.name.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={"الوصف"}
                      style={{ margin: 8 }}
                      placeholder={"الوصف"}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </GridItem>
                  {/* {!editProduct ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="outlined-full-width"
                        label={languages.stock.ar}
                        style={{ margin: 8 }}
                        placeholder={languages.stock.ar}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={(e) => setCount(e.target.value)}
                      />
                    </GridItem>
                  ) : null} */}

                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.price.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.price.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      type={"text"}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.image.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.image.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => fileSelectedHandler(e)}
                      type="file"
                      disabled={checked ? true : false}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      variant="outlined"
                      classes={classes.FormControl}
                      fullWidth
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        style={{ textAlign: "left" }}
                      >
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={category}
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                        fullWidth={true}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {cats.map((c) => {
                          return <MenuItem value={c.id}>{c.name_ar}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <Checkbox
                      checked={checked}
                      onChange={handleChecked}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Input
                      id="multifile"
                      name="multifile"
                      type="file"
                      inputProps={{ multiple: true }}
                      disabled={checked ? false : true}
                      onChange={(e) => {
                        uploadMultipleFiles(e);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {(fileArray || []).map((url, i) => {
                      return (
                        <GoodCard
                          url={url}
                          handleVariants={handleVariants}
                          UID={i}
                        />
                      );
                    })}
                  </GridItem> */}
                </GridContainer>
              </CardBody>
              <CardFooter className={classes.footer}>
                <Button
                  color="primary"
                  onClick={(e) =>
                    editProduct ? submitEditProduct(e) : addProduct(e)
                  }
                  type="submit"
                >
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
