import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";

import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import "assets/css/rat.css";

import SignInSide from "Screens/SignInSide";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import AuthContext from "Utilities/AuthContext";
import GridSpinner from "components/LoaderComponent/Grid";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import GlobalState from "Utilities/GlobalState";
import { CurrencyProvider } from "Utilities/currencyContext";

import "../Utilities/fonts.css";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

// const customTheme = createMuiTheme({
//   palette: {
//     background: {
//       default: "#1c1c1c",
//     },
//     primary: {
//       main: "#880e4f",
//     },
//     secondary: {
//       main: "#00c853",
//     },
//   },
// });

const MedianPath = () => {
  let history = useHistory();
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button
        variant="contained"
        size="large"
        color="primary"
        className={classes.margin}
        onClick={() => {
          history.push("/rtl");
        }}
      >
        Proceed To Dashboard
      </Button>
    </div>
  );
};

const App = () => {
  const { user } = useContext(AuthContext);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    //console.log("Authenticated User: ", user);
    //check if user is logged in or not
    if (user !== null) {
      //redirect home
      setIsAuth(true);
    }
  }, []);

  return (
    <GlobalState>
      <CurrencyProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={SignInSide} exact />
            <Route path="/landing">
              <MedianPath />
            </Route>
            <ProtectedRoute path="/rtl" component={RTL} user={user} />
          </Switch>
        </BrowserRouter>
      </CurrencyProvider>
    </GlobalState>
  );
};

export default App;
