import React from "react";
import { Grid } from "react-loader-spinner";

const GridSpinner = ({ color, size, noxy }) => {
  if (noxy)
    return (
      <Grid
        height={size}
        width={size}
        color={color || "#4fa94d"}
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid
        height={size}
        width={size}
        color={color || "#4fa94d"}
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default GridSpinner;
