/* eslint-disable no-unused-vars */
/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import WidgetsIcon from "@material-ui/icons/Widgets";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import StorageIcon from "@material-ui/icons/Storage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CollectionsIcon from "@material-ui/icons/Collections";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import ViewUsersPage from "Screens/ViewUsersPage";
import CategoryPage from "Screens/CategoryPage";
import ProductsPage from "Screens/ProductsPage";
import ProductsPageUser from "Screens/ProductsPageUser";
import SignOut from "Screens/SignOutPage";
import OrdersPage from "Screens/OrdersPage";
import OrdersPageUser from "Screens/OrdersPageUser";
import SuppliersPage from "Screens/SuppliersPage";
import VariantsPage from "Screens/VariantsPage";
import ProductViewPage from "Screens/ProductViewPage";
import StockPage from "Screens/StockPage";
import CurrencyControlPage from "Screens/CurrencyControlPage";

export const adminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/orders",
    name: "Order View",
    rtlName: "الطلبات",
    icon: AssignmentTurnedInIcon,
    component: OrdersPage,
    layout: "/rtl",
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "المستخدمين",
    icon: Person,
    component: ViewUsersPage,
    layout: "/rtl",
  },
  {
    path: "/category",
    name: "Category",
    rtlName: "الاقسام",
    icon: WidgetsIcon,
    component: CategoryPage,
    layout: "/rtl",
  },

  {
    path: "/products",
    name: "Products",
    rtlName: "المنتجات",
    icon: AllInboxIcon,
    component: ProductsPage,
    layout: "/rtl",
  },
  // {
  //   path: "/variants",
  //   name: "Variants",
  //   rtlName: "الاصناف",
  //   icon: CollectionsIcon,
  //   component: VariantsPage,
  //   layout: "/rtl",
  // },
  // {
  //   path: "/suppliers",
  //   name: "Suppliers",
  //   rtlName: "الموردين",
  //   icon: LocalShippingIcon,
  //   component: SuppliersPage,
  //   layout: "/rtl",
  // },
  // {
  //   path: "/storage",
  //   name: "Storage",
  //   rtlName: "المخزن",
  //   icon: StorageIcon,
  //   component: StockPage,
  //   layout: "/rtl",
  // },
  {
    path: "/currency",
    name: "Currency",
    rtlName: "العملة",
    icon: AttachMoneyIcon,
    component: CurrencyControlPage,
    layout: "/rtl",
  },
  {
    path: "/out",
    name: "Logout",
    rtlName: "تسجيل الخروج",
    icon: ExitToAppIcon,
    component: SignOut,
    layout: "/rtl",
  },
];

export const userRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "المنتجات",
    icon: Dashboard,
    component: ProductsPageUser,
    layout: "/rtl",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "الطلبات",
    icon: Dashboard,
    component: OrdersPageUser,
    layout: "/rtl",
  },
  {
    path: "/out",
    name: "Logout",
    rtlName: "تسجيل الخروج",
    icon: Unarchive,
    component: SignOut,
    layout: "/rtl",
  },
];
