export const languages = {
  login: {},
  addUser: {
    en: "Add User",
    ar: "اظافة مستخدم",
  },
  name: {
    ar: "الاسم",
  },
  stock: {
    ar: "المخزن",
  },
  price: {
    ar: "السعر",
  },
  image: {
    ar: "الصورة",
  },
  category: {
    ar: "القسم",
  },
  phone: {
    ar: "الهاتف",
  },
  email: {
    ar: "الايميل",
  },
  password: {
    ar: "كلمة السر",
  },
};
