/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import Button from "components/CustomButtons/Button";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { CurrencyContext } from "Utilities/currencyContext";

import AuthContext from "Utilities/AuthContext";

const useStyles = makeStyles(styles);

export default function UserProfitsPage() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  let history = useHistory();
  const { returnSpecificFormatted, returnValue } = useContext(CurrencyContext);

  const [profits, setProfits] = useState([]);

  const ViewOrderButton = (order) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem("al-shamkh_order_id", JSON.stringify(order.id));

          setTimeout(() => {
            history.push("/rtl/orderviewuser");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get(
        "/orders/profits/" + user.user.id,
        headers
      );
      let arr = [];

      res.profits.map((p) => {
        arr.push([
          p.id,
          returnValue(p.profit),
          p.created_at,
          ViewOrderButton(p),
        ]);
      });
      setProfits(arr);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h3 className={classes.cardTitleWhite}>الارباح</h3>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Amount", "Date", "-"]}
                tableData={profits}
                dense
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
