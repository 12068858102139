/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { axiosClient } from "Utilities/axiosConfig";
import { languages } from "variables/languages";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function AddUsersPage({ handleEditMode, refresh, editUser }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [trademark, setTrademark] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEdit = () => {
    if (editUser) {
      setName(editUser.name_ar);
      setTrademark(editUser.trademark);
      setPhone(editUser.phone);
      setEmail(editUser.email);
    }
  };

  const addUser = async (e) => {
    try {
      e.preventDefault();
      let payload = {
        name_ar: name,
        trademark: trademark,
        phone: phone,
        email: email,
        password: password,
      };

      if (e.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const resp = await axiosClient.post("/registerUser", payload, {
          headers: headers,
        });
        console.log(resp);
        alert("Success!");
        refresh();
      }
    } catch (err) {
      console.log(err.response.data);
      alert("Error, please try again");
    }
  };

  const submitEditUser = async (event) => {
    event.preventDefault();

    try {
      let payload = {
        name_ar: name,
        trademark: trademark,
        phone: phone,
        email: email,
      };

      console.log(payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
        };
        const res = await axiosClient.post(
          "/users/" + editUser.id + "/edit",
          payload,
          {
            headers: headers,
          }
        );
        console.log(res);
        alert("Success!");
        handleEditMode();
        refresh(editUser.id);
      }
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  useEffect(() => {
    if (editUser) {
      handleEdit();
    }
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>
                {!editUser ? "اضافة مستخدم" : "تعديل مستخدم"}
              </h3>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.name.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.name.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={"الاسم التجاري"}
                      style={{ margin: 8 }}
                      placeholder={"الاسم التجاري"}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={trademark}
                      onChange={(e) => setTrademark(e.target.value)}
                      type="text"
                    />
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email-address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.phone.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.phone.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.email.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.email.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      phone={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                    />
                  </GridItem>
                  {!editUser ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="outlined-full-width"
                        label={languages.password.ar}
                        style={{ margin: 8 }}
                        placeholder={languages.password.ar}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={(e) => setPassword(e.target.value)}
                        type="text"
                      />
                    </GridItem>
                  ) : null}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={(e) => (!editUser ? addUser(e) : submitEditUser(e))}
                  type="submit"
                >
                  Confirm Changes
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
