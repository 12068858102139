/*eslint-disable*/
import React, { useState, useEffect } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import AddUsersPage from "../Screens/AddUsersPage";
import ReusableSearch from "components/reusableSearch";
import GridSpinner from "components/LoaderComponent/Grid";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ViewUsersPage() {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [displayData, setDisplayData] = useState(null);
  const [nothing, setNothing] = useState(false);
  const [error, setError] = useState(false);
  let history = useHistory();

  const ViewUserButton = (id) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem("al-shamkh_user_id", id);
          setTimeout(() => {
            history.push("/rtl/userview");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const handleDisplayData = (finds) => {
    if (finds == "Nothing") {
      setNothing(true);
    } else if (finds !== null) {
      let arr = [];
      finds.map((user, i) => {
        arr.push([
          user.name_ar,
          user.trademark,
          user.phone,
          ViewUserButton(user.id),
        ]);
      });
      setDisplayData(arr);
      setNothing(false);
    } else {
      setDisplayData(users);
      setNothing(false);
    }
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/v1/users", headers);
      let arr = [];
      let pwds = [];
      res.data.map((user) => {
        arr.push([
          user.name_ar,
          user.trademark,
          user.phone,
          ViewUserButton(user.id),
        ]);
      });
      setUsers(arr);
      setDisplayData(arr);
      setError(false);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!displayData && !error) return <GridSpinner />;

  return (
    <div>
      <AddUsersPage refresh={getData} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h3 className={classes.cardTitleWhite}>المستخدمين</h3>
            </CardHeader>
            <CardBody>
              <ReusableSearch
                apiPath={"/users/search/"}
                handleDisplayData={handleDisplayData}
              />
              {nothing ? (
                <GridItem xs={12} sm={12} md={12} alignItems="center">
                  <h3>لا يوجد نتائج</h3>
                </GridItem>
              ) : (
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "الاسم",
                    "الاسم التجاري",
                    "رقم الهاتف",
                    "الايميل",
                  ]}
                  tableData={displayData}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
