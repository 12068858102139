import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { TextField, FormControl } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { axiosClient } from "Utilities/axiosConfig";
import { languages } from "variables/languages";
import { Category } from "@material-ui/icons";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function AddCategoryPage({ editCategory, refresh }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");

  const fileSelectedHandler = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const addCategory = async (event) => {
    event.preventDefault();
    console.log("function ran");
    try {
      let payload = {
        name_ar: name,
        description: description,
        image: file,
      };

      console.log(payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const res = await axiosClient.post("/categories", payload, {
          headers: headers,
        });
        console.log(res);
        alert("Success!");
        refresh();
      }
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  const handleEdit = () => {
    if (editCategory) {
      setName(editCategory.name_ar);
    }
  };

  const submitEditCategory = async (event) => {
    event.preventDefault();
    console.log("function ran");
    try {
      let payload = {
        name_ar: name,
        description: description,
        image: file,
      };

      console.log(payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const res = await axiosClient.post(
          "/categories/" + editCategory.id + "/edit",
          payload,
          {
            headers: headers,
          }
        );
        console.log(res);
        alert("Success!");
        refresh(res.id);
      }
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  useEffect(() => {
    if (editCategory) {
      handleEdit();
    }
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>
                {!editCategory ? "اضافة قسم" : "تعديل قسم"}
              </h3>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.name.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.name.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      value={name}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.image.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.image.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => fileSelectedHandler(e)}
                      type="file"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={(event) =>
                    !editCategory
                      ? addCategory(event)
                      : submitEditCategory(event)
                  }
                  type="submit"
                >
                  confirm
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
