/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

//import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";

//import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import GridSpinner from "components/LoaderComponent/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Button, IconButton } from "@material-ui/core";
import ShopContext from "Utilities/shop-context";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import { CurrencyContext } from "Utilities/currencyContext";
import ReusableSearch from "components/reusableSearch";
import { useHistory } from "react-router-dom";
import HorizontalProductCard from "components/HorizontalProductCard";
import CancelIcon from "@material-ui/icons/Cancel";

// const useStyles = makeStyles(styles);
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    marginBottom: 8,
  },
  media: {
    height: 250,
  },
  button: {
    marginLeft: 4,
    marginRight: 4,
  },
});

export default function ProductsPageUser() {
  const classes = useStyles();
  const { returnValue } = useContext(CurrencyContext);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);
  const [displayData, setDisplayData] = useState(null);
  const [nothing, setNothing] = useState(false);
  const [cats, setCats] = useState(null);
  const [activeCat, setActiveCat] = useState(0);
  let history = useHistory();

  const handleDisplayData = (finds) => {
    if (finds == "Nothing") {
      setNothing(true);
    } else if (finds !== null) {
      setDisplayData(finds);
      setNothing(false);
    } else {
      setDisplayData(products);
      setNothing(false);
    }
  };

  const getCats = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get("/v1/categories", headers);
      setCats(res.data);
    } catch (err) {
      console.log(err.response.data.message);
      setError(true);
    }
  };

  const getData = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get("/v1/products", headers);
      console.log(res);
      setProducts(res.data);
      setDisplayData(res.data);
      setError(false);
    } catch (err) {
      console.log(err.response.data.message);
      setError(true);
    }
  };
  useEffect(() => {
    getData();
    getCats();
  }, []);

  if (!displayData || (!cats && !error)) return <GridSpinner />;

  return (
    <div>
      <h1>Products</h1>
      <ReusableSearch
        apiPath="/products/search/"
        handleDisplayData={handleDisplayData}
      />
      <ShopContext.Consumer>
        {(context) => (
          <GridContainer>
            <GridItem
              xs={12}
              style={{
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cats
                ? cats.map((c) => (
                    <Button
                      variant={activeCat == c.id ? "contained" : "outlined"}
                      color="primary"
                      size="large"
                      className={classes.button}
                      onClick={() => {
                        setActiveCat(c.id);
                      }}
                    >
                      {c.name_ar}
                    </Button>
                  ))
                : null}
              {activeCat !== 0 ? (
                <IconButton
                  onClick={() => {
                    setActiveCat(0);
                  }}
                >
                  <CancelIcon style={{ marginLeft: 8 }} />
                </IconButton>
              ) : null}
            </GridItem>
            {nothing ? (
              <GridItem xs={12} sm={12} md={12} alignItems="center">
                <h3>لا يوجد نتائج</h3>
              </GridItem>
            ) : (
              displayData.map((p) => {
                if (activeCat !== 0) {
                  if (p.category == activeCat) {
                    return (
                      <GridItem xs={12} sm={12} md={6}>
                        <HorizontalProductCard product={p} />
                      </GridItem>
                    );
                  }
                } else {
                  return (
                    <GridItem xs={12} sm={12} md={6}>
                      <HorizontalProductCard product={p} />
                    </GridItem>
                  );
                }
              })
            )}
          </GridContainer>
        )}
      </ShopContext.Consumer>
    </div>
  );
}
