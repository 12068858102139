//search shi9t
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { Grid, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import { axiosClient } from "../Utilities/axiosConfig";
import { Children, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/rtlHeaderLinksStyle.js";
const useStyles = makeStyles(styles);

const ReusableSearch = ({ apiPath, handleDisplayData }) => {
  const [search, setSearch] = useState("");
  //const [finds, setFinds] = useState(null);
  const classes = useStyles();

  const searchHandler = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get(apiPath + search, headers);
      console.log(res);
      // setFinds(res);
      handleDisplayData(res);
      // setError(false);
    } catch (err) {
      if (err.response.data == "Nothing") {
        handleDisplayData("Nothing");
        console.log("nothing rn");
      }
      console.log(err);
      // setError(true);
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-end" justify="center">
      <Grid item xs={12} sm={12} md={10}>
        <TextField
          id="search"
          label="بحث"
          placeholder="بحث"
          style={{ marginTop: 0, marginBottom: 24 }}
          fullWidth
          margin="normal"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="start"
                style={{
                  display: search ? "flex" : "none",
                  cursor: "pointer",
                }}
              >
                <Button
                  color={window.innerWidth > 959 ? "transparent" : "white"}
                  justIcon={window.innerWidth > 959}
                  simple={!(window.innerWidth > 959)}
                  aria-label="Close"
                  className={classes.buttonLink}
                  onClick={(e) => {
                    setSearch("");
                    handleDisplayData(null);
                    // e.target.value = "";
                  }}
                >
                  <CancelIcon />
                </Button>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="end">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            if (e.target.value == "") {
              setSearch("");
              handleDisplayData(null);
            } else {
              setSearch(e.target.value);
              searchHandler();
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ReusableSearch;

// const [displayData, setDisplayData] = useState(null);
//   const [nothing, setNothing] = useState(false);

// const handleDisplayData = (finds) => {
//     if (finds == "Nothing") {
//       setNothing(true);
//     } else if (finds !== null) {
//       setDisplayData(finds);
//       setNothing(false);
//     } else {
//       setDisplayData(products);
//       setNothing(false);
//     }
//   };
// if (!displayData && !error) return <GridSpinner />;

//  <ReusableSearch
//         apiPath={"/products/search/"}
//         handleDisplayData={handleDisplayData}
//       />
// you have to check if nothing then display no data message otherwise display your component

// {
//   nothing ? (
//     <GridItem xs={12} sm={12} md={12} alignItems="center">
//       <h3>لا يوجد نتائج</h3>
//     </GridItem>
//   ) : (
//     <YourComponent />
//   );
// }
