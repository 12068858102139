/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import { adminRoutes, userRoutes } from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/rtlStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";

import AuthContext from "Utilities/AuthContext";
import CartPage from "Screens/Cart";

import ProductViewPage from "Screens/ProductViewPage.js";
import ProductViewPageUser from "Screens/ProductViewPageUser";

import UserViewPage from "Screens/UserViewPage";
import CategoryViewPage from "Screens/CategoryViewPage";
import OrderViewPageUser from "Screens/OrderViewPageUser";
import OrderViewPage from "Screens/OrderViewPage";
import UserProfitsPage from "Screens/UserProfitsPage";
import UserDuesPage from "Screens/UserDuesPage";
import SupplierViewPage from "Screens/SupplierViewPage";
import RestockViewPage from "Screens/RestockViewPage";
import RestockPage from "Screens/RestockPage";
import VariantsPage from "Screens/VariantsPage";
import PaymentsPage from "Screens/PaymentsPage";

let ps;

const SwitchRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.invisible) return null;
        if (prop.layout === "/rtl") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      {/* <Route path="/rtl/orders/:id" component={OrderViewPage} /> */}
      <Route path={"/rtl/cart"} component={CartPage} />
      <Route path={"/rtl/productview"} component={ProductViewPage} />
      <Route path={"/rtl/productviewuser"} component={ProductViewPageUser} />
      <Route path="/rtl/userview" component={UserViewPage} />
      <Route path="/rtl/categoryview" component={CategoryViewPage} />
      <Route path="/rtl/orderview" component={OrderViewPage} />
      <Route path="/rtl/orderviewuser" component={OrderViewPageUser} />
      <Route path="/rtl/userprofits" component={UserProfitsPage} />
      <Route path="/rtl/userdues" component={UserDuesPage} />
      <Route path="/rtl/supplierview" component={SupplierViewPage} />
      <Route path="/rtl/restockview" component={RestockViewPage} />
      <Route path="/rtl/restockpage" component={RestockPage} />
      <Route path="/rtl/variants" component={VariantsPage} />
      <Route path="/rtl/payments" component={PaymentsPage} />
      <Redirect from="/rtl" to="/rtl/dashboard" />
    </Switch>
  );
};

const useStyles = makeStyles(styles);

export default function RTL({ ...rest }) {
  const { user } = useContext(AuthContext);
  const routes = user.user.role == 1 ? adminRoutes : userRoutes;
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("green");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Al-Shamkh"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        rtlActive
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          rtlActive
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {<SwitchRoutes routes={routes} />}
            </div>
          </div>
        ) : (
          <div className={classes.map}>{<SwitchRoutes routes={routes} />}</div>
        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          rtlActive
        /> */}
      </div>
    </div>
  );
}
