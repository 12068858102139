import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

const useStyles = makeStyles({
  card: {
    display: "flex",
    backgroundColor: "#c9c9c9",
    marginBottom: 10,
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: "112px",
    height: "115px",
  },
});

export default function GoodCard(props) {
  const classes = useStyles();
  const { url, handleVariants, UID, addVariant } = props;
  const [usrTitle, setUsrTitle] = useState(null);

  const handleConfirm = (usrTitle, UID) => {
    handleVariants(usrTitle, UID);
  };

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea component="a" href="#">
        <Card className={classes.card}>
          <div className={classes.cardDetails}>
            <CardContent>
              <TextField
                id="variant_title"
                label={"صنف"}
                style={{ margin: 8 }}
                placeholder={"صنف"}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={(e) => {
                  handleConfirm(e.target.value, UID);
                }}
              />
            </CardContent>
          </div>
          <Hidden xsDown>
            <CardMedia
              className={classes.cardMedia}
              image={url}
              //title={product.imageTitle}
            />
          </Hidden>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

GoodCard.propTypes = {
  product: PropTypes.object,
};
