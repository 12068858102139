import React, { useContext, useState } from "react";
import Button from "components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { axiosClient } from "Utilities/axiosConfig";
import { CurrencyContext } from "Utilities/currencyContext";

export default function PaymentModal({ userId, balance, refresh }) {
  const [open, setOpen] = React.useState(false);
  const { returnSpecificFormatted } = useContext(CurrencyContext);
  const [amount, setAmount] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmountChange = (amount) => {
    console.log("amount change in modal", amount);
    setAmount(amount);
  };

  const submitPayment = async () => {
    let headers = {
      Accept: "application/json",
    };
    let payload = {
      user_id: userId,
      amount: amount,
    };
    try {
      const res = await axiosClient.post("/payment", payload, {
        headers: headers,
      });
      refresh();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Button color="danger" onClick={handleClickOpen}>
        عرض
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{"تسديد دفعة"}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "1.5rem" }}
          >
            {returnSpecificFormatted(balance, 1)}
          </DialogContentText>
          <TextField
            variant="filled"
            placeholder="المبلغ"
            onChange={(e) => {
              handleAmountChange(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              submitPayment();
            }}
            color="primary"
            autoFocus
            size="small"
          >
            confirm
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="danger"
            autoFocus
            size="small"
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
