/*eslint-disable*/
import React, { useState, useEffect } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import AddSuppliersPage from "./AddSuppliersPage";
import ReusableSearch from "components/reusableSearch";
import GridSpinner from "components/LoaderComponent/Grid";
import Button from "components/CustomButtons/Button";
import ErrorComponent from "components/errorComponent.js";
import { useHistory } from "react-router-dom";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { Collapse } from "@material-ui/core";
const useStyles = makeStyles(styles);

export default function SuppliersPage() {
  const classes = useStyles();

  const [suppliers, setSuppliers] = useState([]);
  const [currentSupplier, setCurrentSupplier] = useState();
  const [displayData, setDisplayData] = useState(null);
  const [nothing, setNothing] = useState(false);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  let history = useHistory();

  const handleDisplayData = (finds) => {
    if (finds == "Nothing") {
      setNothing(true);
    } else if (finds !== null) {
      let arr = [];
      finds.map((user) => {
        arr.push([user.name_ar, user.trademark, user.phone, user.email]);
      });
      setDisplayData(arr);
      setNothing(false);
    } else {
      setDisplayData(suppliers);
      setNothing(false);
    }
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/suppliers", headers);
      let arr = [];
      res.map((s) => {
        arr.push([s.name, s.trademark, s.phone, ViewSupplierButton(s)]);
      });
      setSuppliers(arr);
      setDisplayData(arr);
      setError(false);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  const ViewSupplierButton = (supplier) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem(
            "al-shamkh_supplier_id",
            JSON.stringify(supplier.id)
          );

          setTimeout(() => {
            history.push("/rtl/supplierview");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    getData();
  }, []);

  if (error) return <ErrorComponent refresh={getData} />;

  if (!displayData && !error) return <GridSpinner />;

  return (
    <div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AddSuppliersPage refresh={getData} />
      </Collapse>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem md={6}>
          <Button
            variant="contained"
            size="normal"
            color="rose"
            style={{ fontSize: 20, width: "80%" }}
            onClick={() => {
              history.push("/rtl/restockpage");
            }}
          >
            <span style={{ margin: 5 }}>شراء بضاعة جديدة</span>
            <AddShoppingCartIcon style={{ fontSize: "Large" }} />
          </Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            variant="contained"
            size="normal"
            color="primary"
            style={{ fontSize: 20, width: "80%" }}
            onClick={() => {
              handleExpandClick();
            }}
          >
            <span style={{ margin: 5 }}>اضافة مورد</span>
            <LocalShippingIcon style={{ fontSize: "large" }} />
          </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h3 className={classes.cardTitleWhite}>الموردين</h3>
            </CardHeader>
            <CardBody>
              <ReusableSearch
                apiPath={"/users/search/"}
                handleDisplayData={handleDisplayData}
              />
              {nothing ? (
                <GridItem xs={12} sm={12} md={12} alignItems="center">
                  <h3>لا يوجد نتائج</h3>
                </GridItem>
              ) : (
                <Table
                  tableHeaderColor="primary"
                  tableHead={["الاسم", "الاسم التجاري", "رقم الهاتف", "-"]}
                  tableData={displayData}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
