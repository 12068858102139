import React, { useState, useEffect, useContext } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { Grid, Typography, TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { CurrencyContext } from "Utilities/currencyContext";
import styles from "assets/jss/material-dashboard-react/views/rtlStyle";
import { makeStyles } from "@material-ui/core";
import { axiosClient } from "Utilities/axiosConfig";

const useStyles = makeStyles(styles);
const CurrencyControlPage = () => {
  const classes = useStyles();
  const {
    usdValue,
    getUsdValue,
    returnSpecificFormatted,
    returnSpecific,
  } = useContext(CurrencyContext);
  const [enteredValue, setEnteredValue] = useState();

  const setUsd = async (e) => {
    const headers = {
      Accept: "application/json",
    };
    let payload = {
      value: enteredValue,
    };

    try {
      const res = await axiosClient.post("/editusd", payload, {
        headers: headers,
      });
      console.log(res.value);
      alert(`success, $ = ${returnSpecificFormatted(res.value, 1)}`);
      getUsdValue();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary">
            <h3 className={classes.cardTitleWhite}>العملات</h3>
          </CardHeader>
          <CardBody>
            <Grid
              item
              xs={12}
              md={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
              }}
            >
              <Typography variant="h4" color="textPrimary">
                {"قيمة الدولار : " + " "}
              </Typography>
              <Typography variant="h4" color="secondary">
                {returnSpecificFormatted(usdValue, 1)}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ marginBottom: 10 }}
              >
                ادخال قيمة جديدة
              </Typography>
              <form className={classes.root} autoComplete="off">
                <TextField
                  placeholder="قيمة الدولار"
                  variant="filled"
                  type={"number"}
                  onChange={(e) => {
                    setEnteredValue(e.target.value);
                  }}
                  label="قيمة الدولار"
                  color={"secondary"}
                />
              </form>
            </Grid>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (enteredValue !== "") {
                  setUsd();
                }
              }}
            >
              confirm
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CurrencyControlPage;
