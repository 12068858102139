import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import LockIcon from "@material-ui/icons/Lock";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { CurrencyContext } from "Utilities/currencyContext";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import ShopContext from "Utilities/shop-context";
import GridSpinner from "components/LoaderComponent/Grid";
import { Link } from "react-router-dom";
import { axiosClient } from "Utilities/axiosConfig";
import AddCategoryPage from "Screens/AddCategoryPage";
import { useHistory } from "react-router-dom";
import AlertDialog from "components/AlertDialog";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
    marginLeft: 0,
    marginRight: 0,
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  btn: {
    marginLeft: 4,
    marginRight: 4,
  },
  gridolian: {
    flexGrow: 1,
  },
  media: {
    height: 250,
    alignSelf: "flex-start",
  },
  kardAction: {
    justifyContent: "center",
  },
}));

export default function CategoryViewPage({}) {
  const classes = useStyles();
  const [category, setCategory] = useState(null);
  const [image, setImage] = useState();

  const { returnValue } = useContext(CurrencyContext);

  const hiddenFileInput = React.useRef(null);

  const [editMode, setEditMode] = useState(false);

  let history = useHistory();

  const handleModal = () => {
    setModalOpen(true);
  };

  const handleCategory = () => {
    var categoryFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_category_id")
    );
    console.log(categoryFromLocalStorage);
    refreshCategory(categoryFromLocalStorage);
    localStorage.removeItem("al-shamkh_category_id");
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const refreshCategory = async (id) => {
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/categories/" + id, headers);

      setCategory(res);
      // setImage(res.category.image);

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const deleteCategory = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.delete("/categories/" + category.id, {
        headers: headers,
      });
      console.log(res);
      //setLoading(false);
      alert("Success!");
      history.push("/rtl/categories");
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  useEffect(() => {
    handleCategory();
  }, []);

  if (!category) return <GridSpinner />;

  return (
    <ShopContext.Consumer>
      {(context) => (
        <Grid container alignItems="center" className={classes.root}>
          <Grid item xs={12} sm={12} md={10}>
            <Link to="/rtl/category">
              <Button color="warning">الرجوع الى الاقسام</Button>
            </Link>

            <Card>
              <CardHeader color="success">
                <h3 className={classes.cardTitleWhite}>{category.name_ar}</h3>
              </CardHeader>

              <CardBody className={classes.content}>
                <Typography variant="h6" color="textSecondary">
                  {"الاسم : "}
                </Typography>
                <Typography variant="h5" color="textPrimary">
                  {category.name_ar}
                </Typography>
              </CardBody>

              <CardFooter>
                <Button
                  color="warning"
                  onClick={() => {
                    handleEditMode();
                  }}
                  className={classes.btn}
                >
                  تعديل
                </Button>

                <AlertDialog text={"متاكد؟"} yes={deleteCategory} />
              </CardFooter>
            </Card>

            {/* <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            /> */}
            {editMode ? (
              <AddCategoryPage
                handleEditMode={handleEditMode}
                refresh={refreshCategory}
                editCategory={category}
              />
            ) : null}
          </Grid>
        </Grid>
      )}
    </ShopContext.Consumer>
  );
}
