/* eslint-disable no-unused-vars */
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { axiosClient } from "Utilities/axiosConfig";
import { languages } from "variables/languages";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function AddSuppliersPage({ refresh }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [trademark, setTrademark] = useState("");
  const [phone, setPhone] = useState("");

  const addSupplier = async (e) => {
    try {
      e.preventDefault();
      let payload = {
        name: name,
        trademark: trademark,
        phone: phone,
      };

      if (e.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const resp = await axiosClient.post("/suppliers", payload, {
          headers: headers,
        });
        console.log(resp);
        alert("Success!");
        refresh();
      }
    } catch (err) {
      console.log(err.response.data);
      alert("Error, please try again");
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>اضافة مورد</h3>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.name.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.name.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={"الاسم التجاري"}
                      style={{ margin: 8 }}
                      placeholder={"الاسم التجاري"}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => setTrademark(e.target.value)}
                      type="text"
                    />
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email-address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={languages.phone.ar}
                      style={{ margin: 8 }}
                      placeholder={languages.phone.ar}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={(e) => addSupplier(e)}
                  type="submit"
                >
                  Add Supplier
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
