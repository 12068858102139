import React, { useContext, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { CurrencyContext } from "Utilities/currencyContext";
import OvalSpinner from "./LoaderComponent/oval";
import { EmojiObjectsSharp, ListAlt } from "@material-ui/icons";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function createData(statement) {
  let arr = [];

  statement.items.map((item) => {
    let obj = {
      name: item.name,
      quantity: item.quantity,
      total_usd: item.total_usd,
      total_iqd: item.total_iqd,
    };

    arr.push(obj);
  });

  return {
    id: statement.id,
    total_usd: statement.total_usd,
    total_iqd: statement.total_iqd,
    profit_user: statement.profit_user,
    date: statement.date,
    month: statement.month,
    year: statement.year,
    items: arr,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { returnSpecificFormatted, returnSpecific } = useContext(
    CurrencyContext
  );

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            color="primary"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">
          <span
            className="kufi"
            align="right"
            style={{ fontWeight: "500", fontSize: 16 }}
          >
            {row.id}
          </span>
        </TableCell>
        <TableCell align="right">
          <span
            className="kufi"
            align="right"
            style={{ fontWeight: "500", fontSize: 16 }}
          >
            {row.date}
          </span>
        </TableCell>
        <TableCell align="right">
          <span
            className="kufi"
            align="right"
            style={{ fontWeight: "500", fontSize: 16 }}
          >
            {`${returnSpecificFormatted(
              row.total_usd
            )} /  ${returnSpecificFormatted(row.total_iqd, 1)} `}
          </span>
        </TableCell>
        <TableCell align="right">
          <span
            className="kufi"
            align="right"
            style={{ fontWeight: "500", fontSize: 16 }}
          >
            {`${returnSpecificFormatted(
              row.profit_user
            )} /  ${returnSpecificFormatted(
              returnSpecific(row.profit_user, 1),
              1
            )} `}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <h4
                className="kufi"
                align="right"
                style={{ fontWeight: "500", fontSize: 20 }}
              >
                المنتجات
              </h4>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h5
                        className="kufi"
                        style={{ fontWeight: "400", textAlign: "center" }}
                      >
                        المنتج
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5
                        className="kufi"
                        style={{ fontWeight: "400", textAlign: "center" }}
                      >
                        الكمية
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5
                        className="kufi"
                        style={{ fontWeight: "400", textAlign: "center" }}
                      >
                        المجموع
                      </h5>
                    </TableCell>
                    {/* <TableCell align="right">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      {/* <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell> */}
                      <TableCell>{historyRow.name}</TableCell>
                      <TableCell align="right">
                        <h5
                          className="kufi"
                          style={{ fontWeight: "400", textAlign: "center" }}
                        >
                          {historyRow.quantity}
                        </h5>
                      </TableCell>
                      <TableCell align="right">
                        <h5
                          className="kufi"
                          style={{ fontWeight: "400", textAlign: "center" }}
                        >
                          {`${returnSpecificFormatted(
                            historyRow.total_usd
                          )} / IQD ${returnSpecificFormatted(
                            historyRow.total_iqd,
                            1
                          )} `}
                        </h5>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

// const rows = [
//   statement.map((s) => {
//     createData(s);
//   }),
// ];

export default function CollapsibleTable({ statement }) {
  const [rows, setRows] = useState(null);

  const handleSetRows = (statement) => {
    let st = [];

    statement.map((s) => {
      st.push(createData(s));
    });
    setRows(st);
  };

  useEffect(() => {
    handleSetRows(statement);
  }, [statement]);

  useEffect(() => {
    console.log("statements", statement);
  });
  if (!rows) return <OvalSpinner />;

  return (
    <TableContainer component={Paper} style={{ backgroundColor: "#c9c9c9" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <ListAlt />
            </TableCell>
            <TableCell />
            <TableCell style={{ fontWeight: "500" }}>
              <h4
                className="kufi"
                style={{ textAlign: "right", fontWeight: "500" }}
              >
                رقم الطلب
              </h4>
            </TableCell>
            <TableCell>
              <h4
                className="kufi"
                style={{ textAlign: "right", fontWeight: "500" }}
              >
                التاريخ
              </h4>
            </TableCell>
            <TableCell>
              <h4
                className="kufi"
                style={{ textAlign: "right", fontWeight: "500" }}
              >
                مجموع مبلغ البضاعة
              </h4>
            </TableCell>
            <TableCell>
              <h4
                className="kufi"
                style={{ textAlign: "right", fontWeight: "500" }}
              >
                مجموع ربح الطلب
              </h4>
            </TableCell>
            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
