import AuthContext from "Utilities/AuthContext";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

const SignOut = () => {
  const { setUser } = useContext(AuthContext);
  let history = useHistory();

  useEffect(() => {
    actuallySignOut();
  }, []);

  const actuallySignOut = () => {
    setUser(null);
    localStorage.setItem("al-shamkh_user", null);
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>You have Logged Out.</h1>
    </div>
  );
};

export default SignOut;
