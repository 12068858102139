import React, { useContext, useState } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/rtlHeaderLinksStyle.js";
import shopContext from "Utilities/shop-context";
import { Link, useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { CurrencyContext } from "Utilities/currencyContext";
import AuthContext from "Utilities/AuthContext";

const useStyles = makeStyles(styles);

export default function RTLNavbarLinks() {
  const context = useContext(shopContext);
  const { toggleCurrency, current } = useContext(CurrencyContext);
  //const [activeCurrency, setActiveCurrency] = useState("USD");
  const { user } = useContext(AuthContext);
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const handleToggle = (event) => {
    if (open && open.contains(event.target)) {
      setOpen(null);
    } else {
      setOpen(event.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleCurrency = (e) => {
    if (e.target.checked) {
      toggleCurrency(e.target.value);
    } else {
      toggleCurrency("USD");
    }
  };

  return (
    <div>
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search,
          }}
          inputProps={{
            placeholder: "جستجو...",
            inputProps: {
              "aria-label": "Search",
            },
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div> */}

      {/* <span style={{ marginLeft: 30, color: "#f50057" }}>USD</span>
      <Switch
        onChange={(e) => {
          handleCurrency(e);
        }}
        color="secondary"
        inputProps={{ "aria-label": "checkbox with default color" }}
        value={"IQD"}
        checked={current == "IQD" ? true : false}
        disabled={user.user.role == 2 ? true : false}
      />
      <span style={{ color: "#f50057" }}>IQD</span> */}
      {user.user.role == 2 ? (
        <Link to="/rtl/cart">
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Cart"
            className={classes.buttonLink}
            // onClick={() => {
            //   history.push("/cart");
            // }}
          >
            <ShoppingCartIcon className={classes.icons} />
            <span className={classes.notifications}>
              {context.cart.reduce((count, curItem) => {
                return count + curItem.quantity;
              }, 0)}
            </span>
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>عربة التسوق</p>
            </Hidden>
          </Button>
        </Link>
      ) : null}

      <div className={classes.manager} style={{ display: "none" }}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={open ? "menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}></span>
          <Hidden mdUp implementation="css">
            <p onClick={handleToggle} className={classes.linkText}>
              الاشعارات
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(open)}
          anchorEl={open}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !open }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleClose}
                      className={classes.dropdownItem}
                    >
                      محمدرضا به ایمیل شما پاسخ داد
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.dropdownItem}
                    >
                      شما ۵ وظیفه جدید دارید
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.dropdownItem}
                    >
                      از حالا شما با علیرضا دوست هستید
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.dropdownItem}
                    >
                      اعلان دیگر
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.dropdownItem}
                    >
                      اعلان دیگر
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
