import React, { useContext, useEffect, useState } from "react";
// import { connect } from 'react-redux';

import ShopContext from "Utilities/shop-context";

// import { removeProductFromCart } from '../store/actions';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import { CurrencyContext } from "Utilities/currencyContext";
import { axiosClient } from "Utilities/axiosConfig";
import AuthContext from "Utilities/AuthContext";
import Button from "components/CustomButtons/Button";
import { useHistory, useParams } from "react-router-dom";
import GridSpinner from "components/LoaderComponent/Grid";
import { TextField } from "@material-ui/core";
import Table from "components/Table/Table.js";
import PrintIcon from "@material-ui/icons/Print";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
  row: {
    padding: 10,
    textAlign: "center",
    color: "textSecondary",
    justifyContent: "space-between",
  },

  button: {
    width: "100%",
  },
};

const useStyles = makeStyles(styles);

const RestockViewPage = () => {
  const classes = useStyles();
  const context = useContext(ShopContext);
  const { returnValue, returnSpecificFormatted } = useContext(CurrencyContext);
  const { user } = useContext(AuthContext);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [received, setReceived] = useState(null);
  const [items, setItems] = useState(null);
  let history = useHistory();

  const handleRestock = () => {
    var restockFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_restock_id")
    );
    getData(restockFromLocalStorage);
    localStorage.removeItem("al-shamkh_restock_id");
  };

  const confirmOrder = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/orders/confirm/" + id, headers);
      console.log(res);
      setCurrentOrder(res.order);
    } catch (err) {
      console.log(err);
    }
  };

  const makeTransaction = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    let payload = {
      received: received,
      restock_id: id,
    };
    try {
      const res = await axiosClient.post("/restocks/transact/" + id, payload, {
        headres: headers,
      });
      console.log(res);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
    }
  };

  const completeOrder = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/orders/complete/" + id, headers);
      console.log(res.order);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelOrder = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/orders/cancel/" + id, headers);
      console.log(res.order);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
    }
  };

  const transactionsArray = (t) => {
    console.log("transactions array ran");
    let arr = [];
    t.forEach((t) => {
      arr.push([t.received, t.remaining, t.created_at]);
    });
    setTransactions(arr);
  };

  const getData = async (orderID) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      let arr = [];
      const res = await axiosClient.get("/restock/" + orderID, headers);
      console.log(res.restock);
      setCurrentOrder(res.restock);
      res.items.map((item) => {
        arr.push([
          item.product_name,
          returnValue(item.purchase_price),
          item.quantity,
          returnValue(item.product_price),
        ]);
      });
      setItems(arr);
      transactionsArray(res.transactions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleRestock();
  }, []);

  if (currentOrder == null || transactions == null || items == null)
    return <GridSpinner />;

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={12} md={8}>
        <Button
          color="warning"
          onClick={() => {
            history.push("/rtl/suppliers");
          }}
          type="submit"
        >
          الرجوع الى الموردين
        </Button>
        <Card>
          <CardHeader color="success">
            <h3 className={classes.cardTitleWhite}>{`${currentOrder.id}`}</h3>
            <h3
              className={classes.cardTitleWhite}
            >{`${currentOrder.supplier_name}`}</h3>
          </CardHeader>

          <CardBody>
            {items.length > 0 ? (
              <Card>
                <CardHeader color="primary">
                  <h3 className={classes.cardTitleWhite}>المنتجات</h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "اسم المنتح",
                      "سعر الشراء",
                      "الكمية",
                      "سعر البيع",
                    ]}
                    tableData={items}
                  />
                </CardBody>
                <CardFooter>
                  <div className={classes.row}>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        // marginRight: "auto",
                      }}
                    >
                      {returnValue(currentOrder.total)}
                    </div>
                  </div>
                </CardFooter>
              </Card>
            ) : (
              <h1>لا يوجد منتجات</h1>
            )}

            <Card>
              <CardHeader color="warning">
                <h3 className={classes.cardTitleWhite}>الدفعات</h3>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["مبلغ الدفعة", "المبلغ الباقي", "التاريخ"]}
                  tableData={transactions}
                />
              </CardBody>
            </Card>
          </CardBody>
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className={classes.row}>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      // marginRight: "auto",
                    }}
                  >
                    {returnValue(currentOrder.total)}
                  </div>
                </div>
              </Grid>
              <Grid xs={6}>
                <div className={classes.row}>
                  <IconButton
                    variant="filled"
                    color="primary"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                </div>
              </Grid>

              <>
                <Grid item xs={12}>
                  <div className={classes.row}>
                    <TextField
                      id="outlined-full-width"
                      label={"المبلغ المستلم"}
                      style={{ margin: 8, width: "90%" }}
                      placeholder={"المبلغ المستلم"}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      onChange={(e) => setReceived(e.target.value)}
                      type="text"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.row}>
                    <Button
                      variant="contained"
                      color="success"
                      component="span"
                      onClick={() => makeTransaction(currentOrder.id)}
                      type="submit"
                      className={classes.button}
                    >
                      استلام مبلغ
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.row}>
                    <Button
                      variant="contained"
                      color="danger"
                      component="span"
                      onClick={() => {
                        cancelOrder(currentOrder.id);
                      }}
                      className={classes.button}
                    >
                      الغاء
                    </Button>
                  </div>
                </Grid>
              </>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RestockViewPage;
