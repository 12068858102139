import React from "react";
import { Redirect } from "react-router-dom";

const AuthContext = React.createContext({
  user: null,
  setUser: () => {},
  // token: null,
  // setToken: () => {},
});

export default AuthContext;
