import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import LockIcon from "@material-ui/icons/Lock";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { CurrencyContext } from "Utilities/currencyContext";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import ShopContext from "Utilities/shop-context";
import GridSpinner from "components/LoaderComponent/Grid";
import { Link } from "react-router-dom";
import { axiosClient } from "Utilities/axiosConfig";
import AddUsersPage from "Screens/AddUsersPage";
import { useHistory } from "react-router-dom";
import AlertDialog from "components/AlertDialog";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Table from "components/Table/Table";

//icon imports
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import Cloud from "@material-ui/icons/Cloud";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CollapsibleTable from "components/CollapsibleTable";
import OvalSpinner from "components/LoaderComponent/oval";
import PaymentModal from "components/PaymentModal";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "Noto Kufi Arabic, sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
    marginLeft: 0,
    marginRight: 0,
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  btn: {
    marginLeft: 4,
    marginRight: 4,
  },
  gridolian: {
    flexGrow: 1,
  },
  media: {
    height: 250,
    alignSelf: "flex-start",
  },
  kardAction: {
    justifyContent: "center",
  },
}));

export default function UserViewPage() {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState(null);
  const [image, setImage] = useState();

  const { returnValue, returnSpecificFormatted } = useContext(CurrencyContext);

  const hiddenFileInput = React.useRef(null);

  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [months, setMonths] = useState();
  const [statements, setStatements] = useState();
  const [selectedStatements, setSelectedStatements] = useState(null);

  let history = useHistory();

  const handleModal = () => {
    setModalOpen(true);
  };

  const ViewOrderButton = (order) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem("al-shamkh_order_id", JSON.stringify(order.id));

          setTimeout(() => {
            history.push("/rtl/orderview");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const handleUser = () => {
    var userFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_user_id")
    );
    console.log(userFromLocalStorage);
    refreshUser(userFromLocalStorage);
    localStorage.removeItem("al-shamkh_user_id");
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const refreshUser = async (id) => {
    const headers = {
      Accept: "application/json",
    };
    try {
      let unconfirmed = [];
      let confirmed = [];
      let complete = [];

      const res = await axiosClient.get("/users/" + id, headers);

      res.orders.unconfirmed.map((o, i) => {
        unconfirmed.push([
          o.id,
          `${returnSpecificFormatted(o.total_iqd, 1)} `,
          o.status == 1 ? "قيد التاكيد" : "قيد التجهيز",
          ViewOrderButton(o),
        ]);
      });
      res.orders.confirmed.map((o) => {
        confirmed.push([
          o.id,
          `${returnSpecificFormatted(o.total_iqd, 1)} `,
          o.status == 1 ? "قيد التاكيد" : "قيد التجهيز",
          ViewOrderButton(o),
        ]);
      });
      res.orders.complete.map((o) => {
        complete.push([
          o.id,
          `${returnSpecificFormatted(o.total_iqd, 1)} `,
          o.status == 1
            ? "قيد التاكيد"
            : o.status == 2
            ? "قيد التجهيز"
            : "كامل",
          ViewOrderButton(o),
        ]);
      });
      setStatements(res.statements);
      setMonths(res.months);
      setUser(res.user);
      setOrders({ unconfirmed, confirmed, complete });
      // setImage(res.user.image);

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const deleteUser = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.delete("/users/" + user.id, {
        headers: headers,
      });
      console.log(res);
      //setLoading(false);
      alert("Success!");
      history.push("/rtl/users");
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const refreshWorkaround = () => {
    refreshUser(user.id);
  };

  const modalToggler = (id, balance) => {
    return (
      <PaymentModal userId={id} balance={balance} refresh={refreshWorkaround} />
    );
  };

  useEffect(() => {
    handleUser();
  }, []);

  if (!user || !orders || !statements || !months) return <GridSpinner />;

  return (
    <ShopContext.Consumer>
      {(context) => (
        <Grid container alignItems="center" className={classes.root}>
          <Grid item xs={12} sm={12} md={10}>
            <Link to="/rtl/users">
              <Button color="warning">الرجوع الى المستخدمين</Button>
            </Link>

            <Card>
              <CardHeader color="success">
                <h3 className={classes.cardTitleWhite}>
                  {user.trademark || user.name_ar}
                </h3>
              </CardHeader>

              <CardBody className={classes.content}>
                <Typography variant="h6" color="textSecondary">
                  {"الاسم : "}
                </Typography>
                <Typography variant="h5" color="textPrimary">
                  {user.name_ar}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {"رقم الهاتف : "}
                </Typography>
                <Typography variant="h5" color="textPrimary">
                  {user.phone}
                </Typography>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Typography variant="h6" color="textSecondary">
                    {"الايميل : "}
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    {user.email}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {"الباسورد : "}
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    {user.workaround}
                  </Typography>
                </Collapse>
              </CardBody>

              <CardFooter>
                <Button
                  color="warning"
                  onClick={() => {
                    handleEditMode();
                  }}
                  className={classes.btn}
                >
                  تعديل
                </Button>

                <AlertDialog text={"متاكد؟"} yes={deleteUser} />
                <IconButton
                  variant="filled"
                  color="primary"
                  onClick={handleExpandClick}
                >
                  <LockIcon />
                </IconButton>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader color="danger">
                <h3 className={classes.cardTitleWhite}>المستحقات</h3>
              </CardHeader>
              <CardBody className={classes.content}>
                <Typography variant="h4" color="textPrimary">
                  {returnSpecificFormatted(user.balance)}
                </Typography>
              </CardBody>
              <CardFooter>
                {modalToggler(user.id, user.balance)}
                <Button
                  color="primary"
                  onClick={() => {
                    localStorage.setItem("al-shamkh_user_id", user.id);
                    setTimeout(() => {
                      history.push("/rtl/payments");
                    }, 300);
                  }}
                >
                  عرض الدفعات المستلمة
                </Button>
              </CardFooter>
            </Card>

            {/* <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            /> */}
            {editMode ? (
              <AddUsersPage
                handleEditMode={handleEditMode}
                refresh={refreshUser}
                editUser={user}
              />
            ) : null}
            <Grid item xs={12}>
              <CustomTabs
                title={<h3 className={classes.cardTitleWhite}>الطلبات</h3>}
                headerColor="primary"
                tabs={[
                  {
                    tabName: "قيد التأكيد",
                    tabIcon: NewReleasesIcon,
                    tabContent: (
                      <Table
                        tableHeaderColor="primary"
                        tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                        tableData={orders.unconfirmed}
                        dense
                      />
                    ),
                  },
                  {
                    tabName: "قيد التجهيز",
                    tabIcon: CheckCircleIcon,
                    tabContent: (
                      <Table
                        tableHeaderColor="primary"
                        tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                        tableData={orders.confirmed}
                        dense
                      />
                    ),
                  },
                  {
                    tabName: "مكتملة",
                    tabIcon: Cloud,
                    tabContent: (
                      <Table
                        tableHeaderColor="primary"
                        tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                        tableData={orders.complete}
                        dense
                      />
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="info">
                  <h3 className={classes.cardTitleWhite}>كشف حساب</h3>
                </CardHeader>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: 30 }} className="kufi">
                      الشهر
                    </span>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={months}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let arr = [];

                        statements.map((s) => {
                          if (
                            e.target.value.month == s.month &&
                            e.target.value.year == s.year
                          ) {
                            arr.push(s);
                          }
                        });
                        setSelectedStatements(arr);
                        setSelectedMonth(
                          `${e.target.value.month}/${e.target.value.year}`
                        );
                      }}
                      fullWidth={false}
                      style={{ width: "50%" }}
                      variant="filled"
                      placeholder="اختار شهر"
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      {months.map((m, i) => {
                        return (
                          <MenuItem
                            value={m}
                          >{`${m.month}/${m.year}`}</MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {selectedMonth ? (
                      <CollapsibleTable statement={selectedStatements} />
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ShopContext.Consumer>
  );
}
