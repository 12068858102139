import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import AuthContext from "Utilities/AuthContext";
import App from "views/App";

const MainRouter = () => {
  const [user, setUser] = useState(null);
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <App />
    </AuthContext.Provider>
  );
};

ReactDOM.render(<MainRouter />, document.getElementById("root"));
