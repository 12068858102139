/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import GridSpinner from "components/LoaderComponent/Grid";
import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import Button from "components/CustomButtons/Button";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { CurrencyContext } from "Utilities/currencyContext";
import ReusableSearch from "components/reusableSearch";

const useStyles = makeStyles(styles);

export default function OrdersPage() {
  const classes = useStyles();
  const match = useRouteMatch();
  let history = useHistory();
  const [orders, setOrders] = useState([]);
  const [displayData, setDisplayData] = useState(null);
  const [nothing, setNothing] = useState(false);
  const [error, setError] = useState(false);
  const { returnSpecificFormatted } = useContext(CurrencyContext);

  const ViewOrderButton = (order) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem("al-shamkh_order_id", JSON.stringify(order.id));

          setTimeout(() => {
            history.push("/rtl/orderview");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const handleDisplayData = (finds) => {
    if (finds == "Nothing") {
      setNothing(true);
    } else if (finds !== null) {
      let arr = [];
      finds.map((orders, i) => {
        arr.push([
          orders.id,
          orders.user_name,
          orders.user_phone,
          `${returnSpecificFormatted(orders.total_iqd, 1)} `,
          ViewOrderButton(orders),
        ]);
      });
      setDisplayData(arr);
      setNothing(false);
    } else {
      setDisplayData(orders);
      setNothing(false);
    }
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/orders", headers);
      let arr = [];
      res.map((orders) => {
        arr.push([
          orders.id,
          orders.user_name,
          orders.user_phone,
          `${returnSpecificFormatted(orders.total_iqd, 1)} `,
          ViewOrderButton(orders),
        ]);
      });
      setOrders(arr);
      setDisplayData(arr);
      setError(false);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };
  useEffect(() => {
    getData();
    console.log("match", match);
  }, []);

  if (!displayData && !error) return <GridSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h3 className={classes.cardTitleWhite}>الطلبات</h3>
              {/* <p className={classes.cardCategoryWhite}>
                کارکنان جدید از ۱۵ آبان ۱۳۹۶
              </p> */}
            </CardHeader>
            <CardBody>
              <ReusableSearch
                apiPath={"/orders/search/"}
                handleDisplayData={handleDisplayData}
              />
              {nothing ? (
                <GridItem xs={12} sm={12} md={12} alignItems="center">
                  <h3>لا يوجد نتائج</h3>
                </GridItem>
              ) : (
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "رقم الطلب",
                    "صاحب الطلب",
                    "رقم الهاتف",
                    "المجموع ",
                    "-",
                  ]}
                  tableData={displayData}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
