import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { CurrencyContext } from "Utilities/currencyContext";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import ShopContext from "Utilities/shop-context";
import {
  Card as Kard,
  CardActionArea as KardActionArea,
  CardMedia as KardMedia,
  CardActions as KardActions,
} from "@material-ui/core";
import GridSpinner from "components/LoaderComponent/Grid";
import { Link } from "react-router-dom";
import GoodCard from "components/GoodCard";
import { axiosClient } from "Utilities/axiosConfig";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { getImageSize } from "react-image-size";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
    marginLeft: 0,
    marginRight: 0,
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
  media: {
    height: 250,
    alignSelf: "flex-start",
  },
  kardAction: {
    justifyContent: "center",
  },
}));

export default function ProductViewPageUser({}) {
  const classes = useStyles();
  const [product, setProduct] = useState(null);
  const [image, setImage] = useState();
  const [variants, setVariants] = useState([]);
  const { returnValue } = useContext(CurrencyContext);

  const hiddenFileInput = React.useRef(null);
  const [fileArray, setFileArray] = useState([]);
  const [rawVariants, setRawVariants] = useState();
  const [selectedVariant, setSelectedVariant] = useState({ index: -1 });
  const [description, setDescription] = useState(null);

  const handleProduct = () => {
    var productFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_product_id")
    );
    refreshProduct(productFromLocalStorage);
    localStorage.removeItem("al-shamkh_product_id");
  };

  const handleChange = (e) => {
    // setImage(null);
    let arr = [];
    let filez = [];
    let rawvrnt = [];
    arr.push(e.target.files);
    if (variants.length < 1) {
      filez.push(product.image);
      rawvrnt.push({
        index: 0,
        title: "",
        image: product.image,
        product: product.id,
      });
    }
    for (let i = 0; i < arr[0].length; i++) {
      filez.push(URL.createObjectURL(arr[0][i]));
      let vrnt = {
        index: 1,
        title: "",
        image: arr[0][i],
        product: product.id,
      };
      rawvrnt.push(vrnt);
    }
    setRawVariants(rawvrnt);
    setFileArray(filez);
    console.log(filez, rawvrnt);
  };

  const handleVariants = (title, VID) => {
    setRawVariants((v) =>
      v.map((obj) => {
        if (obj.index === VID) {
          return { ...obj, title: title };
        }
        return obj;
      })
    );
    console.log(rawVariants);
  };

  const handleVariantSelect = (thing) => {
    setDescription(thing.description || product.description);
    setSelectedVariant(thing);
    handleImageDimensions(thing.image);
    console.log("selected variant: ", selectedVariant);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const addVariant = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    let payload = {};
    if (rawVariants.length > 1) {
      payload = {
        index: 1,
        title: rawVariants[1].title,
        image: rawVariants[1].image,
        product: rawVariants[1].product,
        dftitle: rawVariants[0].title,
        dfimage: "product_" + product.id + rawVariants[0].image.slice(-4),
      };
    } else if (rawVariants.length == 1) {
      payload = {
        index: variants.length,
        title: rawVariants[0].title,
        image: rawVariants[0].image,
        product: rawVariants[0].product,
      };
    }
    try {
      console.log("payload:", payload);
      const res = await axiosClient.post("/variants", payload, {
        headers: headers,
      });
      console.log(res);
      //setLoading(false);
      alert("Success!");
      refreshProduct(product.id);
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  const handleImageDimensions = async (url) => {
    const { width, height } = await getImageSize(url);
    setImage({ url: url, width: width, height: height });
  };

  const refreshProduct = async (id) => {
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/v1/products/" + id, headers);

      setProduct(res.data);
      setDescription(res.data.description);
      handleImageDimensions(res.data.image);
      setVariants(res.data.variants);
      setFileArray([]);
      setRawVariants();
      setSelectedVariant({ index: -1 });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleProduct();
  }, []);

  if (!product || !image) return <GridSpinner />;

  return (
    <ShopContext.Consumer>
      {(context) => (
        <Grid container alignItems="center" className={classes.root}>
          <Grid item xs={12} sm={12} md={10}>
            <Link to="/rtl/products">
              <Button color="warning">الرجوع الى المنتجات</Button>
            </Link>

            <Card>
              <CardHeader color="success">
                <h3 className={classes.cardTitleWhite}>{product.name_ar}</h3>
              </CardHeader>

              <CardBody className={classes.content}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <Kard className={classes.root}>
                      <KardActionArea>
                        <Gallery withDownloadButton>
                          <Item
                            original={image.url}
                            thumbnail={image.url}
                            width={image.width}
                            height={image.height}
                          >
                            {({ ref, open }) => (
                              <KardMedia
                                className={classes.media}
                                image={image.url}
                                title="Contemplative Reptile"
                                ref={ref}
                                onClick={open}
                              />
                            )}
                          </Item>
                        </Gallery>
                        <KardActions className={classes.kardAction}>
                          <Typography
                            variant="h6"
                            color="textsecondary"
                            align="center"
                          >
                            {selectedVariant.title || null}
                          </Typography>
                        </KardActions>
                      </KardActionArea>
                    </Kard>
                  </Grid>
                </div>

                {variants.length > 0 ? (
                  <>
                    <Typography variant="h6" color="textSecondary">
                      {"الاصناف : "}
                    </Typography>
                    <div>
                      {variants.map((v, i) => (
                        <Button
                          color={
                            v.index == selectedVariant.index ? "success" : ""
                          }
                          onClick={() => {
                            handleVariantSelect(v);
                          }}
                          style={{ padding: 5, marginLeft: 4, marginRight: 4 }}
                        >
                          <img
                            style={{ width: 35, height: 40, borderRadius: 4 }}
                            src={v.image}
                          />
                        </Button>
                      ))}
                    </div>
                  </>
                ) : null}
                <Typography variant="h6" color="textSecondary">
                  {"السعر : "}
                </Typography>
                <Typography variant="h5" color="textPrimary">
                  {returnValue(product.price)}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {"الوصف : "}
                </Typography>
                <p style={{ fontSize: 17, marginTop: "2px" }}>{description}</p>
              </CardBody>

              <CardFooter>
                <Button
                  color="success"
                  onClick={() => {
                    variants.length > 0 && selectedVariant.index == -1
                      ? alert("يرجى اختيار صنف!")
                      : context.addProductToCart({
                          id: product.id,
                          title:
                            variants.length > 0 && selectedVariant.index !== -1
                              ? product.name_ar + `(${selectedVariant.title})`
                              : product.name_ar,
                          price: product.price,
                          image: image,
                        });
                  }}
                >
                  <ShoppingCartRoundedIcon fontSize="large" color="action" />
                </Button>
                {/* <Button color="info" onClick={handleClick}>
                  اضافة صنف
                </Button> */}
              </CardFooter>
            </Card>
            {(fileArray || []).map((url, i) => {
              return (
                <GoodCard url={url} handleVariants={handleVariants} UID={i} />
              );
            })}
            <>
              {fileArray.length > 0 ? (
                <Button
                  color="success"
                  onClick={() => {
                    addVariant();
                  }}
                >
                  Confirm
                </Button>
              ) : null}
            </>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </Grid>
        </Grid>
      )}
    </ShopContext.Consumer>
  );
}
