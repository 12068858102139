/*eslint-disable*/
import React, { useState, useEffect } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import {
  Card as Kard,
  CardMedia as KardMedia,
  CardActionArea as KardActionArea,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import Button from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import OrderViewPage from "./OrderViewPage";
import GridSpinner from "components/LoaderComponent/Grid";

const useStyles = makeStyles(styles);

export default function VariantsPage() {
  const classes = useStyles();
  let history = useHistory();

  const [product, setProduct] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [variantId, setVariantId] = useState(null);
  const [variantTitle, setVariantTitle] = useState(null);
  const [variantDescription, setVariantDescription] = useState(null);
  const [confirmPressed, setConfirmPressed] = useState(false);

  const handleProduct = () => {
    var productFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_product_id")
    );
    refreshProduct(productFromLocalStorage);
    localStorage.removeItem("al-shamkh_product_id");
  };

  const refreshProduct = async (id) => {
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/v1/products/" + id, headers);

      setProduct(res.data);

      console.log("Variant main products: ", res);
    } catch (err) {
      console.log(err);
    }
  };

  const updateVariant = async () => {
    setConfirmPressed(!confirmPressed);
    const headers = {
      Accept: "application/json",
    };

    try {
      let payload = {
        title: variantTitle,
        description: variantDescription,
      };

      const res = await axiosClient.post(
        "/variants/" + variantId + "/edit",
        payload,
        { headers: headers }
      );

      setVariantId(null);
      setVariantTitle(null);
      setVariantDescription(null);
      setEditMode(false);
      refreshProduct(product.id);
      setConfirmPressed(false);
      console.log("Variant main products: ", res);
    } catch (err) {
      console.log(err);
      setConfirmPressed(false);
    }
  };

  useEffect(() => {
    handleProduct();
  }, []);

  if (!product) return <GridSpinner />;

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      style={{
        display: "flex",

        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Grid item xs={12} sm={12} md={10}>
        <Button
          color="warning"
          onClick={() => {
            localStorage.setItem(
              "al-shamkh_product_id",
              JSON.stringify(product.id)
            );

            setTimeout(() => {
              history.push("/rtl/productview");
            }, 700);
          }}
        >
          الرجوع الى المنتح
        </Button>
        <Card>
          <CardHeader color="info">
            <Typography variant="h4" style={{ fontWeight: 300 }}>
              {product.name_ar}
            </Typography>
          </CardHeader>
          <CardBody>
            <Grid container>
              {product.variants.map((v) => {
                return (
                  <Grid item md={3} xs={12}>
                    <Kard style={{ backgroundColor: "#c9c9c9" }}>
                      <KardMedia
                        image={v.image}
                        style={{ height: 250, alignSelf: "flex-start" }}
                      />
                      <KardActionArea
                        style={{ padding: 5 }}
                        onClick={() => {
                          setVariantId(v.id);
                          setVariantTitle(v.title);
                          setVariantDescription(v.description || "");
                          setEditMode(true);
                        }}
                      >
                        <Typography variant="h6">{v.title}</Typography>
                        <Typography variant="subtitle2">
                          {v.description}
                        </Typography>
                      </KardActionArea>
                    </Kard>
                  </Grid>
                );
              })}
              {editMode ? (
                <Grid item xs={12}>
                  <TextField
                    id="outlined-full-width"
                    label={"الاسم"}
                    style={{ margin: 8 }}
                    placeholder={"الاسم"}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={variantTitle}
                    onChange={(e) => setVariantTitle(e.target.value)}
                  />
                  <TextField
                    id="outlined-full-width"
                    label={"الوصف"}
                    style={{ margin: 8 }}
                    placeholder={"الوصف"}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={variantDescription}
                    onChange={(e) => setVariantDescription(e.target.value)}
                  />
                  <Button
                    color="primary"
                    disabled={confirmPressed ? true : false}
                    onClick={() => {
                      updateVariant();
                    }}
                  >
                    confirm
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
}
