import React from "react";
import { Oval } from "react-loader-spinner";

const OvalSpinner = ({ size }) => {
  return (
    <Oval
      height={size}
      width={size}
      color="#9830b0"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#9830b0"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default OvalSpinner;
