import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";

import { CurrencyContext } from "Utilities/currencyContext";
import { useHistory } from "react-router-dom";
import AuthContext from "Utilities/AuthContext";

const useStyles = makeStyles({
  card: {
    display: "flex",
    backgroundColor: "#c9c9c9",
    marginBottom: 10,
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
    height: 160,
  },
});

export default function HorizontalProductCard(props) {
  const classes = useStyles();
  const { returnValue } = useContext(CurrencyContext);
  const { user } = useContext(AuthContext);
  const { product } = props;
  let history = useHistory();

  return (
    <CardActionArea
      onClick={() => {
        localStorage.setItem(
          "al-shamkh_product_id",
          JSON.stringify(product.id)
        );

        setTimeout(() => {
          history.push(
            user.user.role == 1 ? "/rtl/productview" : "/rtl/productviewuser"
          );
        }, 700);
      }}
    >
      <Card className={classes.card}>
        <div className={classes.cardDetails}>
          <CardContent>
            <Typography gutterBottom variant="h6" component={"h2"}>
              {product.name_ar}
            </Typography>
            <Typography
              variant="h5"
              color="textPrimary"
              component={"h2"}
              style={{ marginTop: "auto" }}
            >
              <strong>{returnValue(product.price)}</strong>
            </Typography>
          </CardContent>
        </div>

        <CardMedia
          className={classes.cardMedia}
          image={
            product.image
              ? product.image
              : "https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg"
          }
        />
      </Card>
    </CardActionArea>
  );
}
HorizontalProductCard.propTypes = {
  product: PropTypes.object,
};
