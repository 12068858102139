import React, { useState, useEffect } from "react";
import { iqdFormat } from "./iqdFormatter";
import UsdFormatter from "./usdFormatter";
import { axiosClient } from "./axiosConfig";

export const CurrencyContext = React.createContext();

export const CurrencyProvider = (props) => {
  const [usdValue, setUsdValue] = useState();
  const [current, setCurrent] = useState("IQD");

  const getUsdValue = async () => {
    const resp = await axiosClient.get("/usd");

    setUsdValue(parseFloat(resp[0].value));
  };

  useEffect(() => {
    getUsdValue();
  }, []);

  const toggleCurrency = (currency) => {
    setCurrent(currency);
    console.log("current currency: " + currency);
  };
  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  //commented after fixing wierd decimals
  // const returnValue = (price) => {
  //   if (current == "IQD") {
  //     return `${iqdFormat(price * usdValue)} IQD`;
  //   } else {
  //     return UsdFormatter(parseFloat(price));
  //   }
  // };

  //commented while working on canceling usd
  // const returnValue = (price) => {
  //   if (current == "IQD") {
  //     return `${iqdFormat(parseInt(roundToTwo(price * usdValue)))} IQD`;
  //   } else {
  //     return UsdFormatter(parseFloat(price));
  //   }
  // };

  const returnValue = (price) => {
    if (current == "IQD") {
      return `${iqdFormat(parseInt(price))} IQD`;
    } else {
      return UsdFormatter(parseFloat(price));
    }
  };

  const returnSpecific = (price, iqd) => {
    if (iqd) {
      return parseFloat(price) * parseFloat(usdValue);
    } else {
      return parseFloat(price);
    }
  };

  const returnSpecificFormatted = (price, iqd) => {
    if (iqd) {
      return `${iqdFormat(price)} IQD`;
    } else {
      return UsdFormatter(parseFloat(price));
    }
  };

  const returnUsdExplicit = (iqd) => {
    let val = parseFloat(iqd / usdValue);
    return roundToTwo(val);
  };

  return (
    <CurrencyContext.Provider
      value={{
        toggleCurrency,
        returnValue,
        returnSpecific,
        returnUsdExplicit,
        returnSpecificFormatted,
        usdValue,
        setUsdValue,
        getUsdValue,
        current,
      }}
    >
      {props.children}
    </CurrencyContext.Provider>
  );
};
