/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import Button from "components/CustomButtons/Button";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { CurrencyContext } from "Utilities/currencyContext";

import AuthContext from "Utilities/AuthContext";

const useStyles = makeStyles(styles);

export default function OrdersPageUser() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  let history = useHistory();
  const { returnSpecificFormatted } = useContext(CurrencyContext);

  const [orders, setOrders] = useState([]);

  const ViewOrderButton = (order) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem("al-shamkh_order_id", JSON.stringify(order.id));

          setTimeout(() => {
            history.push("/rtl/orderviewuser");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get(
        "/orders/user/" + user.user.id,
        headers
      );
      let arr = [];
      res.map((orders) => {
        arr.push([
          orders.id,
          `${returnSpecificFormatted(
            orders.total_usd
          )} / IQD ${returnSpecificFormatted(orders.total_iqd, 1)} `,
          orders.status == 1
            ? "قيد التاكيد"
            : orders.status == 2
            ? "قيد التجهيز"
            : "كامل",
          ViewOrderButton(orders),
        ]);
      });
      setOrders(arr);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h3 className={classes.cardTitleWhite}>الطلبات</h3>
              {/* <p className={classes.cardCategoryWhite}>
                کارکنان جدید از ۱۵ آبان ۱۳۹۶
              </p> */}
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                tableData={orders}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
